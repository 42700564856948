button {
    background-color: transparent;
    border: 0;

    @include box-shadow(0, 0, 0, 0);
}

.btn {
    text-transform: uppercase;
    font-weight: 400;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    padding: 16px 8px;
    text-align: center;
    height: auto;
}

.vb-btn-full {
    background-color: $vb-grey;
    position: relative;
    overflow: hidden;
    width: 100%;
    border: 2px 1px 2px 1px;
    font-size: $middle-font-size;
    height: 64px;
    cursor: pointer;
    color: $vb-light !important;

    @include border-radius(0);

    &:hover {
        color: $vb-light;
        background-color: $vb-primary;
    }

    &:active,
    &:focus {
        color: $vb-light;
        background-color: $vb-secondary;
    }
}

.dropdown-menu {
    padding: 0;
    margin: 0;
    left: 0;
    background-color: transparent;
    outline: none;
    border: none;
    width: auto;

    @include box-shadow(0, 0, 0, 0);
}


.btn:active, .btn.active {
    @include box-shadow(0, 0, 0, 0);
}

.vb-btn {
    font-size: 12px;
    background-color: transparent;
    height: 48px;
    width: 48px;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;

    // padding: 6px 6px 0 6px;
    cursor: pointer;
    color: $vb-primary;
    font-weight: 500;

    @include border-radius(0);

    border: solid 2px transparent;

    &:hover {
        color: $vb-light;
        background-color: $vb-primary;
    }

    &:active,
    &:focus {
        height: 48px;
        width: 48px;
        color: $vb-light;
        background-color: $vb-secondary;
        border: solid 2px $vb-secondary;
    }

    .bar {
        &:before {
            content: "";
            position: absolute;
            top: 28%;
            left: 9px;
            width: 30px;
            height: 22px;
            border-top: 13px double #fff;
            border-bottom: 4px solid #fff;
        }
    }
}

.vb-btn-icon {
    cursor: pointer;
    float: left;
    width: 48px;
    padding: 0;
    margin: 0;

    &:hover,
    &:focus {
        opacity: 0.5;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: $vb-grey;
    }

    &:last-child:after {
        padding: 32px;
    }
}

.socialContainer {
    padding: 16px 0 32px;
    margin: auto;
}


@media (min-width: $break-huge) {
    .btn-nav-container {
        padding: 16px 0 0 0;
        margin: 0;
        width: 100%;
        display: block;
    }

    .vb-btn-down {
        font-size: 0.6em;
        cursor: default;
        background-color: $vb-grey;

        @include border-radius(0);

        a {
            &:hover,
            &:focus,
            &:active {
                outline: none;
                border: none;
                text-decoration: none;
                color: $vb-primary;
            }
        }
    }
}

//2500px< & >1200px
@media (max-width: $break-huge) and (min-width: $break-large) {
    .navbar-brand {
        z-index: 9999;
        height: 48px;
        width: 48px;
        padding: 8px;
    }


    .vb-btn-down {
        font-size: 0.6em;
        cursor: default;
        background-color: $vb-grey;

        @include border-radius(0);

        a {
            &:hover,
            &:focus,
            &:active {
                outline: none;
                border: none;
                text-decoration: none;
                color: $vb-primary;
            }
        }
    }
}

//1200px< & >767px
@media (max-width: $break-large) and (min-width: 815px) {
    .navbar-brand {
        z-index: 9999;
        height: 48px;
        width: 48px;
        padding: 8px;
    }

    .vb-btn-down {
        font-size: 0.6em;
        cursor: default;
        background-color: $vb-grey;

        @include border-radius(0);

        a {
            &:hover,
            &:focus,
            &:active {
                outline: none;
                border: none;
                text-decoration: none;
                color: $vb-primary;
            }
        }
    }
}

//767px< & >480px
@media (max-width: 815px) and (min-width: $break-small) {
    .navbar-brand {
        z-index: 9999;
        height: 48px;
        width: 48px;
        padding: 8px;
    }

    .vb-btn-down {
        font-size: 0.6em;
        cursor: default;
        background-color: $vb-grey;

        @include border-radius(0);

        a {
            &:hover,
            &:focus,
            &:active {
                outline: none;
                border: none;
                text-decoration: none;
                color: $vb-primary;
            }
        }
    }
}

//480px< & >320px
@media (max-width: $break-small) and (min-width: $break-tiny) {
    .navbar-brand {
        z-index: 9999;
        height: 48px;
        width: 48px;
        padding: 8px;
    }
}

//320px<
@media (max-width: $break-tiny) {
    .navbar-brand {
        z-index: 9999;
        height: 48px;
        width: 48px;
        padding: 8px;
    }
}
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

@media (min-width: $break-huge) {
    .right-title{
        text-align: right;
    }

    #resume_container {
        color: $vb-grey;
        background-color: #ebf5ff;

        .title {
            padding-bottom: 32px;
        }

        .row {
            margin: 1.2rem 0;
        }

        .upper-space {
            margin-top: 32px;
        }

        .sub-title {
            padding: 8px 0;
        }

        .side-text {
            font-size: $middle-font-size;
            color: $vb-light-grey;
        }

        .small-title {
            word-break: break-all;

            ul {
                li.micro-text {
                    padding-top: 16px;
                    font-style: italic;
                    color: $vb-light-grey;
                }
            }
        }


        img {
            max-width: 512px;
            width: 100%;
            height: 100%;
        }
    }

    .division-line {
        &::before {
            content: '';
            position: absolute;
            border-bottom: 1px $vb-light-grey solid;
            height: 100%;
            width: 100%;

            @include transform(translateY(-45%));
        }
    }
}

@media (max-width: $break-huge) and (min-width: $break-large) {

    .right-title{
        text-align: right;
    }

    #resume_container {
        color: $vb-grey;
        background-color: #ebf5ff;

        .title {
            padding-bottom: 32px;
        }

        .row {
            margin: 1.2rem 0;
        }

        .upper-space {
            margin-top: 32px;
        }

        .sub-title {
            padding: 8px 0;
        }

        .side-text {
            font-size: $middle-font-size;
            color: $vb-light-grey;
        }

        .small-title {
            word-break: break-all;

            ul {
                li.micro-text {
                    padding-top: 16px;
                    font-style: italic;
                    color: $vb-light-grey;
                }
            }
        }

        img {
            max-width: 512px;
            width: 100%;
            height: 100%;
        }
    }

    .division-line {
        &::before {
            content: '';
            position: absolute;
            border-bottom: 1px $vb-light-grey solid;
            height: 100%;
            width: 100%;

            @include transform(translateY(-45%));
        }
    }
}

@media (max-width: $break-large) and (min-width: $break-big) {
    .right-title{
        text-align: right;
    }

    #resume_container {
        color: $vb-grey;
        background-color: #ebf5ff;

        .title {
            padding-bottom: 32px;
        }

        .row {
            margin: 1.2rem 0;
        }

        .upper-space {
            margin-top: 32px;
        }

        .sub-title {
            padding: 8px 0;
        }

        .side-text {
            font-size: $middle-font-size;
            color: $vb-light-grey;
        }

        .small-title {
            word-break: break-all;

            ul {
                li.micro-text {
                    padding-top: 8px;
                    font-style: italic;
                    color: $vb-light-grey;
                }
            }
        }

        img {
            max-width: 512px;
            width: 100%;
            height: 100%;
        }
    }

    .division-line {
        &::before {
            content: '';
            position: absolute;
            border-bottom: 1px $vb-light-grey solid;
            height: 100%;
            width: 100%;

            @include transform(translateY(-45%));
        }
    }
}

//991px< & >767px
@media (max-width: $break-big) and (min-width: $break-medium) {
    .right-title{
        text-align: right;
    }

    #resume_container {
        color: $vb-grey;
        background-color: #ebf5ff;

        .text-container {
            padding: 32px;
            margin: auto;
        }

        .row {
            margin: 1.2rem 0;
        }

        .upper-space {
            margin-top: 32px;
        }

        .sub-title {
            padding: 8px 0;
        }

        .side-text {
            font-size: $middle-font-size;
            color: $vb-light-grey;
        }

        .small-title {
            word-break: break-all;

            ul {
                margin: 0;

                li.sub-text {
                    padding-bottom: 16px;
                }

                li.micro-text {
                    padding-top: 8px;
                    font-style: italic;
                    color: $vb-light-grey;
                }
            }
        }

        img {
            max-width: 512px;
            width: 100%;
            height: 100%;
        }
    }

    .division-line {
        &::before {
            content: '';
            position: absolute;
            border-bottom: 1px $vb-light-grey solid;
            height: 100%;
            width: 100%;

            @include transform(translateY(-45%));
        }
    }
}

//767px< & >480px
@media (max-width: $break-medium) and (min-width: $break-small) {

    #resume_container {
        .text-container {
            padding-top: 16px;
            padding-bottom: 32px;
            margin: auto;
        }

        .title {
            ul li {
                position: static;
                min-width: 256px;
                max-width: 512px;
                display: block;
                margin: 0 auto;
            }
        }

        color: $vb-grey;
        background-color: #ebf5ff;

        .row {
            margin: 0.6rem 0;
        }

        .upper-space {
            margin-top: 32px;
        }

        .sub-title {
            padding: 8px 0;
        }

        .side-text {
            font-size: $middle-font-size;
            color: $vb-light-grey;
        }

        .small-title {
            word-break: break-all;
            
            ul {
                margin: 0;

                li.sub-text {
                    padding-bottom: 16px;
                }

                li.micro-text {
                    padding-top: 8px;
                    font-style: italic;
                    color: $vb-light-grey;
                }
            }
        }

        img {
            max-width: 512px;
            width: 100%;
            height: 100%;
        }
    }

    .division-line {
        &::before {
            content: '';
            position: absolute;
            border-bottom: 1px $vb-light-grey solid;
            height: 100%;
            width: 100%;

            @include transform(translateY(-45%));
        }
    }
}

//480px< & >320px
@media (max-width: $break-small) and (min-width: $break-tiny) {
    #resume_container {
        .text-container {
            padding-top: 16px;
            padding-bottom: 32px;
            margin: auto;
        }

        color: $vb-grey;
        background-color: #ebf5ff;

        .row {
            margin: 0.6rem 0;
        }

        .upper-space {
            margin-top: 32px;
        }

        .sub-title {
            padding: 4px 0;
        }

        .side-text {
            font-size: $middle-font-size;
            color: $vb-light-grey;
        }

        .small-title {
            word-break: break-all;

            ul {
                li.sub-text {
                    padding-bottom: 16px;
                }

                li.micro-text {
                    padding-top: 8px;
                    font-style: italic;
                    color: $vb-light-grey;
                }

                margin: 0;
            }
        }

        img {
            max-width: 512px;
            width: 100%;
            height: 100%;
        }
    }

    .division-line {
        &::before {
            content: '';
            position: absolute;
            border-bottom: 1px $vb-light-grey solid;
            height: 100%;
            width: 100%;

            @include transform(translateY(-45%));
        }
    }
}

//320px<
@media (max-width: $break-tiny) {
    #resume_container {
        .text-container {
            padding-top: 16px;
            padding-bottom: 32px;
            margin: auto;
        }

        color: $vb-grey;
        background-color: #ebf5ff;

        .row {
            margin: 0.6rem 0;
        }

        .upper-space {
            margin-top: 32px;
        }

        .sub-title {
            padding: 4px 0;
        }

        .side-text {
            font-size: $middle-font-size;
            color: $vb-light-grey;
        }

        .small-title {
            ul {
                li.sub-text {
                    padding-bottom: 16px;
                }

                li.micro-text {
                    padding-top: 8px;
                    font-style: italic;
                    color: $vb-light-grey;
                }

                margin: 0;
            }
        }

        img {
            max-width: 512px;
            width: 100%;
            height: 100%;
        }
    }

    .division-line {
        &::before {
            content: '';
            position: absolute;
            border-bottom: 1px $vb-light-grey solid;
            height: 100%;
            width: 100%;

            @include transform(translateY(-45%));
        }
    }
}
nav {
    width: 100%;
    height: 48px;
    position: fixed;
    line-height: 48px;
    background: #fff;
    z-index: 2;
    background-color: $vb-primary;
    font-size: $base-font-size;

    .content {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }


    ul a {
        padding: 0 12px;
    }

    a.icon {
        vertical-align: middle;
        height: 32px;
        padding: 0;

        img {
            height: 32px;
            width: auto;
        }
    }

    .links {
        a:link, a:visited {
            text-decoration: none;
            color: $vb-light;
        }
    }
}

// Apply styling up to devices smaller than tablets (exclude tablets)
@include mq($until: mobile) {
    nav {
        width: 100%;
        height: 48px;
        line-height: 48px;

        .content {
            // // flex-direction: column;
            // flex-wrap: nowrap;
            // justify-content: space-between;
            // align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
        }

        a.icon {
            vertical-align: middle;
            height: 32px;
            padding: 0 16px;

            img {
                height: 32px;
                width: auto;
            }
        }
    }
}


// Apply styling up to devices smaller than tablets (exclude tablets)
@include mq($from: mobile, $until: tablet) {
    nav {
        width: 100%;
        height: 48px;
        line-height: 48px;

        .content {
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
        }

        a.icon {
            vertical-align: middle;
            height: 32px;
            padding: 0 16px;

            img {
                height: 32px;
                width: auto;
            }
        }
    }
}


// Same thing, in landscape orientation
@include mq($until: tablet, $and: "(orientation: landscape)") {
    nav {}
}


// Apply styling to tablets up to desktop (exclude desktop)
@include mq(desktop) {
    nav {}
}
article.js-container {
    height: 100vh;
    width: 100%;
    display: block;
    position: relative;
}

.diagram-body {
    background-color: #999;
    width: 100%;
    height: 100%;
    display: block;

    #data-menu {
        border: none;
        background-color: transparent;
    }

    .svg {
        height: 100%;
    }
}

.data-nav, .data-menu {
    width: auto;
}

.data-menu {
    .options-button {
        opacity: 1;
    }
}

.svgcontainer {
    position: absolute;
}

.description {
    font-size: 18px;
    font-family: ubuntu;
    font-weight: 200;
    width: auto;
    height: auto;
    padding: 32px;
    text-transform: initial;
}

.btn-navbar-brand {
    &:hover,
    &:focus {
        cursor: pointer;
    }
}

.axis path,
.axis line {
    fill: none;
    stroke: #fff;
    stroke-width: 0;
    shape-rendering: crispEdges;
}

.axis text {
    font-family: ubuntu;
    font-weight: 200;
    font-size: 12px;
    fill: #fff;
}

//2500px< & >1200px
@media (max-width: $break-huge) and (min-width: $break-large) {
    .description {
        font-size: 18px;
        font-family: ubuntu;
        font-weight: 200;
        width: auto;
        height: auto;
        padding: 32px;
        text-transform: initial;
    }
}

@media (max-width: $break-large) and (min-width: $break-big) {}

//1200px< & >767px
@media (max-width: $break-big) and (min-width: 815) {
    .yText {
        display: inline-block;
        width: 60%;
    }
}

//767px< & >480px
@media (815) and (min-width: $break-small) {
    .yText {
        width: 60%;
    }
}

//480px< & >320px
@media (max-width: $break-small) and (min-width: $break-tiny) {
    .yText {
        display: inline-block;
        width: 60%;
    }

    .description {
        font-size: 18px;
        font-family: ubuntu;
        font-weight: 200;
        width: auto;
        height: auto;
        padding: 16px;
        text-transform: initial;
    }
}

//320px<
@media (max-width: $break-tiny) {
    .yText {
        display: inline-block;
        width: 60%;
    }

    .description {
        font-size: 18px;
        font-family: ubuntu;
        font-weight: 200;
        width: auto;
        height: auto;
        padding: 16px;
        text-transform: initial;
    }
}
// link styles

a {
    text-decoration: underline;
    color: $vb-light;
    font-weight: 600;

    &:link {
        color: $vb-primary;
        text-decoration: underline;
    }

    &:visited {
        color: darken($vb-primary, 20);
    }

    &:hover,
    &:focus {
        text-decoration: none;
        color: $vb-light;
        background-color: $vb-primary;
    }

    &:active {
        text-decoration: none;
        color: $vb-light;
        background-color: $vb-secondary;
    }
}
// card styles

.card {
    &:first-child {
        margin-top: 0;
    }

    position: relative;
    margin: 1rem 0;
    background-color: black;

    @include card-radius;
}

.small.card {
    @extend .card;

    height: 256px;
}

.medium.card {
    @extend .card;

    height: 256px;
}

.large.card, .medium.card, .small.card {
    @extend .card;

    position: relative;

    .card-image {
        @include card-radius;
        @include drop-shadow;

        max-height: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;

        .card-title {
            color: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
            max-width: 100%;
            padding: 16px;

            h2, p {
                padding: 4px 0;
            }
        }

        &:active,
        &:focus,
        &:hover {
            //I am super ashamed of this, but I find no better way of 
            //overriding an inline style rule added to work with 
            //nunchucks templating engine
            background-image: url("") !important;
            background-color: $vb-primary;
            -webkit-transition: -webkit-box-shadow .25s;
            transition: -webkit-box-shadow .25s;
            transition: box-shadow .25s;
            transition: box-shadow .25s, -webkit-box-shadow .25s;
        }
    }
}
.secondary-container {
    font-size: $middle-font-size;
    font-weight: $font-light;
    background-color: $vb-grey;
    color: $vb-light;
    padding: 32px;
}

.paragraph-container {
    padding: 32px 0;

    .col-md-6, .col-md-12, .col-md-4 {
        padding: 16px;
    }

    img {
        padding: 16px 0;
    }

    .sub-container {
        padding-bottom: 32px;
    }
}

.paragraph-container {
    p {
        padding: 8px 0;
    }

    img {
        width: 100%;
        height: 100%;
    }
}

.paragraph-container--unpadded {
    .col-md-6, .col-md-12, .col-md-4 {
        padding: 32px;
    }

    padding: 0 0 64px 0;
}

.paragraph-container--icons {
    a {
        color: $vb-primary;

        &:hover,
        &:focus {
            color: $vb-light;
            background-color: $vb-primary;
        }

        &:active {
            color: $vb-light;
            background-color: $vb-secondary;
        }
    }

    @extend .paragraph-container;

    .col-md-4 {
        padding: 16px 0;
    }

    img {
        width: 50%;
        height: 50%;
    }
}

.text-container {
    line-height: $base-line-height;
}

.row {
    .page-header {
        padding: 8px 0;
        margin: 0 0 16px 0;
    }
}

@media (min-width: $break-huge) {
    .paragraph-container--unpadded {
        .col-md-6, .col-md-12, .col-md-4 {
            padding: 32px;
        }

        padding: 0 0 64px 0;
    }

    .text-block {
        padding: 32px 0;
    }

    .text-container {
        padding: 32px;
        width: 900px;
        margin: auto;

        .col-md-6 {
            padding-right: 16px;
        }
    }

    .text-container-small {
        padding: 16px 0;
    }

    .jumbo-text {
        padding: 64px;
        font-weight: $font-bold;
        font-size: $base-jumbo-size;
        text-align: left;
        margin: 0 auto;
    }

    .gallery-text {
        font-weight: $font-light;
        font-size: $base-font-size;
        line-height: $font-semi-bold-line-height;
    }

    .document-text {
        font-weight: $font-light;
        font-size: $base-font-size / 1.25;
        line-height: $base-line-height;
    }
}

@media (max-width: $break-huge) and (min-width: $break-large) {
    .paragraph-container--unpadded {
        .col-md-6, .col-md-12, .col-md-4 {
            padding: 32px;
        }

        padding: 0 0 64px 0;
    }

    .text-block {
        padding: 32px 0;
    }


    .text-container {
        padding: 32px;
        width: 900px;
        margin: auto;

        .col-md-6 {
            padding-right: 16px;
        }
    }

    .text-container-small {
        padding: 16px 0;
    }

    .gallery-text {
        font-weight: $font-light;
        font-size: $base-font-size;
        line-height: $font-semi-bold-line-height;
    }

    .document-text {
        font-weight: $font-light;
        font-size: $base-font-size / 1.25;
        line-height: $base-line-height;
    }

    .jumbo-text {
        padding: 64px;
        font-weight: $font-bold;
        font-size: $base-jumbo-size;
        text-align: left;

        // width: 900px;
        margin: 0 auto;
    }
}

@media (max-width: $break-large) and (min-width: $break-big) {
    .paragraph-container--unpadded {
        .col-md-6, .col-md-12, .col-md-4 {
            padding: 32px;
        }

        padding: 0 0 64px 0;
    }

    .text-container {
        padding: 32px;
        width: 900px;
        margin: auto;

        .col-md-6 {
            padding-right: 16px;
        }
    }

    .text-block {
        padding: 32px 0;
    }

    .text-container-small {
        padding: 16px 0;
    }

    .gallery-text {
        font-weight: $font-light;
        font-size: $base-font-size;
        line-height: $font-semi-bold-line-height;
    }

    .document-text {
        font-weight: $font-light;
        font-size: $base-font-size / 1.25;
        line-height: $base-line-height;
    }

    .jumbo-text {
        padding: 32px;
        font-weight: $font-bold;
        font-size: $base-jumbo-size;
        text-align: left;
        margin: 0 auto;
    }
}

@media (max-width: $break-big) and (min-width: $break-medium) {
    .paragraph-container--unpadded {
        .col-md-6, .col-md-12, .col-md-4 {
            padding: 32px;
        }

        padding: 0 0 32px 0;
    }

    .text-block {
        padding: 16px 0;
    }

    .text-container-small {
        padding: 8px 0;
    }

    .text-container {
        padding: 32px;
        max-width: 900px;
        margin: auto;

        .col-md-6 {
            padding-right: 16px;
        }
    }

    .gallery-text {
        font-weight: $font-light;
        font-size: $base-font-size;
        line-height: $font-semi-bold-line-height;
    }

    .jumbo-text {
        padding: 32px;
        font-weight: $font-bold;
        font-size: $base-jumbo-size;
        text-align: left;
        margin: 0 auto;
    }

    .document-text {
        font-weight: $font-light;
        font-size: $base-font-size / 1.25;
        line-height: $base-line-height;
    }
}

@media (max-width: $break-medium) and (min-width: $break-small) {
    .paragraph-container--unpadded {
        .col-md-6, .col-md-12, .col-md-4 {
            padding: 32px;
        }

        padding: 0 0 64px 0;
    }

    .paragraph-container {
        padding: 32px 0;
    }

    .text-block {
        padding: 16px 0;
    }

    .text-container {
        font-size: $base-font-size;
        padding: 32px;

        .col-md-6 {
            padding-right: 16px;
        }
    }

    .text-container-small {
        padding: 8px 0;
    }

    .gallery-text {
        font-weight: $font-light;
        font-size: $base-font-size;
        line-height: $font-semi-bold-line-height;
    }

    .jumbo-text {
        padding: 32px;
        font-weight: $font-bold;
        font-size: $mobile-jumbo-size;
        text-align: left;
        margin: 0 auto;
    }

    .document-text {
        font-weight: $font-light;
        font-size: $base-font-size;
        line-height: $base-line-height;
    }
}

@media (max-width: $break-small) and (min-width: $break-tiny) {
    .paragraph-container--unpadded {
        .col-md-6, .col-md-12, .col-md-4 {
            padding: 32px;
        }

        padding: 0 0 32px 0;
    }

    .paragraph-container {
        padding: 32px 0;
    }

    .text-block {
        padding: 16px 0;
    }

    .gallery-text {
        font-weight: $font-light;
        font-size: $mobile-font-size;
        line-height: $font-semi-bold-line-height;
    }

    .document-text {
        font-weight: $font-light;
        font-size: $mobile-font-size;
        line-height: $base-line-height;
    }

    .text-container-small {
        padding: 8px 0;
    }

    .jumbo-text {
        padding: 16px;
        font-weight: $font-bold;
        font-size: $mobile-jumbo-size;
        text-align: left;

        // width: 298px;
        margin: 0 auto;
    }

    .text-container {
        font-size: $mobile-font-size;
        width: 90%;
        padding: 32px 0;

        .col-md-6 {
            padding-right: 16px;
        }
    }
}

@media (max-width: $break-tiny) {
    .paragraph-container--unpadded {
        .col-md-6, .col-md-12, .col-md-4 {
            padding: 32px;
        }

        padding: 0 0 32px 0;
    }

    .paragraph-container {
        padding: 32px 0;
    }

    .text-block {
        padding: 16px 0;
    }


    .text-container {
        font-size: $mobile-font-size;
        width: 90%;
        padding: 32px 0;

        .col-md-6 {
            padding-right: 16px;
        }
    }

    .text-container-small {
        padding: 8px 0;
    }

    .gallery-text {
        font-weight: $font-light;
        font-size: $mobile-font-size;
        line-height: $font-semi-bold-line-height;
    }

    .jumbo-text {
        padding: 16px;
        font-weight: $font-bold;
        font-size: $mobile-jumbo-size;
        text-align: left;
        margin: 0 auto;
    }

    .document-text {
        font-weight: $font-light;
        font-size: $mobile-font-size;
        line-height: $base-line-height;
    }
}
footer {
    font-family: $vb-body-font-family;
    font-size: 10px;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 32px;
    line-height: $base-line-height;

    p {
        padding: 0;
        margin: 0;
    }

    img {
        width: 48px;
        height: 48px;
    }
}

.foot {
    background-color: $vb-grey;
    color: $vb-light;
    z-index: 1;
}

#contact {
    background-color: $vb-dark;
}

//>1200px
@media (min-width: $break-large) {
    .bottom {
        text-align: left;
        height: auto;
        padding: 32px;
        background-color: $vb-grey;
        font-size: 18px;

        .contact-info {
            width: auto;
        }
    }

    .footer-text {
        display: block;
        background-color: $vb-grey;
        height: 100%;
        padding: 16px;
        margin: 32px 0;
    }
}

//1200px< & >767px
@media (max-width: $break-large) and (min-width: $break-medium) {
    .bottom {
        text-align: left;
        height: auto;
        padding: 32px;
        background-color: $vb-grey;
        font-size: 16px;
    }

    .footer-text {
        display: block;
        background-color: $vb-grey;
        height: 100%;
    }
}

//767px< & >480px
@media (max-width: $break-medium) and (min-width: $break-small) {
    .footer-text {
        font-size: 16px;
        display: block;
        background-color: $vb-grey;
    }
}

//480px< & >320px
@media (max-width: $break-small) and (min-width: $break-tiny) {}

//320px<
@media (max-width: $break-tiny) {}
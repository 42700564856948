.center {
    display: table;
    width: 100%;
    height: 101%;
}

.v-align {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.box {
    width: 100%;
    display: inline-block;
    vertical-align: top;
}

.round_radius {
    a > img {
        @include border-radius(48px);
    }
}

.container {
    padding: 48px 0 0 0;
    margin: 0;
    width: 100%;
}

.container--padded {
    @extend .container;

    padding: 128px 0;
}

.container--gradient {
    @extend .container;

    background: #ff621a;

    /* Old browsers */
    background: -moz-linear-gradient(top, #00a2de 0%, #1a2839 100%);

    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #00a2de 0%, #1a2839 100%);

    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #00a2de 0%, #1a2839 100%);

    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a2de', endColorstr='#1a2839',GradientType=0 );

    /* IE6-9 */
}

.container--gradient_light {
    background: #ff621a;

    /* Old browsers */
    background: -moz-linear-gradient(top, #00a2de 0%, #0c668e 100%);

    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #00a2de 0%, #0c668e 100%);

    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #00a2de 0%, #0c668e 100%);

    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a2de', endColorstr='#0c668e',GradientType=0 );

    /* IE6-9 */
}

.container--gradient_dark {
    background: #ff621a;

    /* Old browsers */
    background: -moz-linear-gradient(top, #144663 0%, #1a2839 75%);

    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #144663 0%, #1a2839 75%);

    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #144663 0%, #1a2839 75%);

    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#144663', endColorstr='#1a2839',GradientType=0 );

    /* IE6-9 */
}

.container--gradient_artent {
    background: #ff621a;

    /* Old browsers */
    background: -moz-linear-gradient(top, #fff 0%, #975FBF 100%);

    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #fff 0%, #975FBF 100%);

    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #fff 0%, #975FBF 100%);

    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#c4a4db',GradientType=0 );

    /* IE6-9 */
}

.container--gradient_artent-inverse {
    background: #fff;

    /* Old browsers */
    background: -moz-linear-gradient(top, #975FBF 0%, #fff 100%);

    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #975FBF 0%, #fff 100%);

    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #975FBF 0%, #fff 100%);

    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c4a4db', endColorstr='#fff',GradientType=0 );

    /* IE6-9 */
}

.text-box {
    img {
        max-width: 512px;
        width: 100%;
        height: auto;
        display: block;
        margin: auto;
    }
}

.gallery-body {
    background-color: #999;
    width: 100%;
    height: 100%;
    margin: auto;
    display: block;

    #data-menu {
        border: none;
        background-color: transparent;
    }

    .svg {
        height: 100%;
    }
}

.gallery-image {
    a {
        &:hover {
            background-color: transparent;
        }
    }

    a > img {
        &:hover {
            opacity: 0.75;
        }

        &:focus,
        &:active {
            opacity: 0.5;
        }
    }
}

.gallery-image--medium {
    @extend .gallery-image;

    img {
        width: 50%;
        height: 50%;
    }
}

.paragraph-container {
    img {
        width: 100%;
        height: 100%;
    }
}

.paragraph-container--icons {
    a {
        color: $vb-primary;

        &:hover,
        &:focus {
            color: $vb-light;
            background-color: $vb-primary;
        }

        &:active {
            color: $vb-light;
            background-color: $vb-secondary;
        }
    }

    @extend .paragraph-container;

    .col-md-4 {
        padding: 16px 0;
    }

    img {
        width: 50%;
        height: 50%;
    }
}

@media (min-width: $break-huge) {
    .paragraph-container--icons {
        img {
            width: 50%;
            height: 50%;
        }
    }

    .gallery-image {
        height: auto;

        img {
            width: 100%;
            height: 100%;
        }

        a > img {
            &:hover {
                opacity: 0.75;
                background-color: transparent;
            }

            &:focus,
            &:active {
                opacity: 0.5;
            }
        }
    }

    .gallery-image--medium {
        img {
            width: 50%;
            height: 50%;
        }
    }

    .gallery-image--small {
        img {
            max-width: 30%;
            height: 20%;
        }
    }

    .headshot-container {
        padding-bottom: 32px;

        img {
            margin: auto;
            height: 196px;
            width: 196px;
        }
    }

    .container {
        padding-bottom: 16px;

        .gallery-image {
            img {
                width: 1440px;
                display: block;
                margin: auto;
            }
        }
    }

    .container--padded {
        padding: 128px 0;
    }

    .text-box {
        margin: auto;
        width: 768px;
        max-width: 960px;
        word-wrap: break-word;
        display: block;
    }

    .gallery-text {
        padding: 128px;
        margin: auto;
        min-width: 768px;
        max-width: 960px;
        word-wrap: break-word;
        display: block;
    }

    .image_container {
        .col-lg-12 {
            padding: 0  128px;
        }

        .col-xs-12 {
            padding: 0;
        }
    }

    .gallery {
        height: 100%;
        width: 100%;
        display: inline-block;
        position: relative;
    }

    .gallerytile {
        height: 512px;
        padding: 0;
        background-color: #39393D;
        border-top: solid 2px $vb-primary;

        span.gallery-image {
            background-repeat: no-repeat;

            a {
                &:hover {
                    background-color: $vb-primary;
                }
            }
        }
    }

    span.header-image {
        padding: 16px;
        height: $tile-height-medium;
        background-repeat: no-repeat;
    }


    #steammobile {
        @include background-image("../img/work/thumbs/gallery_thumb_steam.jpg");
    }

    #granditude {
        @include background-image("../img/work/thumbs/gallery_thumb_granditude.jpg");
    }

    #granditude {
        @include background-image("../img/work/thumbs/gallery_thumb_granditude.jpg");
    }

    #prettyugly {
        @include background-image("../img/work/thumbs/gallery_thumb_prettyugly.jpg");
    }

    #barbus {
        @include background-image("../img/work/thumbs/gallery_thumb_copafeel.jpg");
    }

    #adhop {
        @include background-image("../img/work/thumbs/gallery_thumb_adhop.jpg");
    }

    #dukebox {
        @include background-image("../img/work/thumbs/gallery_thumb_dukebox.jpg");
    }

    #visualbear {
        @include background-image("../img/work/thumbs/gallery_thumb_visualbear.jpg");
    }

    #lynx {
        @include background-image("../img/work/thumbs/gallery_thumb_lynx.jpg");
    }

    #sos {
        @include background-image("../img/work/thumbs/gallery_thumb_sos.jpg");
    }
}

.gallery-image--full {
    // @extend .gallery-image;
    margin-bottom:-8px;
    img {
        max-width: 100%;
    }

}

//2500px< & >1200px
@media (max-width: $break-huge) and (min-width: $break-large) {
    .paragraph-container--icons {
        img {
            width: 50%;
            height: 50%;
        }
    }

    .gallery-image {
        height: auto;

        a > img {
            &:hover {
                opacity: 0.75;
                background-color: transparent;
            }

            &:focus,
            &:active {
                opacity: 0.5;
            }
        }
    }

    .gallery-image--medium {
        img {
            width: 50%;
            height: 50%;
        }
    }

    .gallery-image--small {
        img {
            max-width: 30%;
            height: 20%;
        }
    }

    .headshot-container {
        padding-bottom: 64px;

        img {
            display: block;
            margin: auto;
            height: 196px;
            width: 196px;
        }
    }

    .container {
        padding-bottom: 16px;

        .gallery-image {
            img {
                display: block;
                margin: auto;
                max-width: 768px;
            }

            .gallery-image--medium {
                img {
                    max-width: 512px;
                }
            }
        }
    }

    .container--padded {
        padding: 128px 0;
    }

    .text-box {
        padding: 0 16px;
        margin: auto;
        width: 768px;
        max-width: 960px;
        word-wrap: break-word;
        display: block;
    }

    .gallery-text {
        padding: 128px;
        margin: auto;
        width: 768px;
        max-width: 960px;
        word-wrap: break-word;
        display: block;
    }

    .image_container {
        .col-lg-12 {
            padding: 0  128px;
        }

        .col-xs-12 {
            padding: 0;
        }
    }

    .gallery {
        height: 100%;
        width: auto;
        margin: auto;
        display: inline-block;
        position: relative;
    }

    .gallerytile {
        height: 512px;
        padding: 0;
        background-color: $vb-grey;
        border-top: solid 2px $vb-primary;

        &:hover {
            background-color: #fff;
        }

        span.gallery-image {
            background-repeat: no-repeat;
        }
    }

    span.header-image {
        padding: 16px;
        height: $tile-height-medium;
        background-repeat: no-repeat;
    }

    #steammobile {
        @include background-image("../img/work/thumbs/gallery_thumb_steam.jpg");
    }

    #granditude {
        @include background-image("../img/work/thumbs/gallery_thumb_granditude.jpg");
    }

    #prettyugly {
        @include background-image("../img/work/thumbs/gallery_thumb_prettyugly.jpg");
    }

    #barbus {
        @include background-image("../img/work/thumbs/gallery_thumb_copafeel.jpg");
    }

    #adhop {
        @include background-image("../img/work/thumbs/gallery_thumb_adhop.jpg");
    }

    #dukebox {
        @include background-image("../img/work/thumbs/gallery_thumb_dukebox.jpg");
    }

    #visualbear {
        @include background-image("../img/work/thumbs/gallery_thumb_visualbear.jpg");
    }

    #lynx {
        @include background-image("../img/work/thumbs/gallery_thumb_lynx.jpg");
    }

    #sos {
        @include background-image("../img/work/thumbs/gallery_thumb_sos.jpg");
    }
}

//1280px< & >900px
@media (max-width: $break-large) and (min-width: $break-big) {
    .paragraph-container--icons {
        img {
            width: 50%;
            height: 50%;
        }
    }

    .gallery-image {
        height: auto;

        img {
            width: 100%;
            max-width: 768px;
            height: 100%;
        }

        a > img {
            &:hover {
                opacity: 0.75;
                background-color: transparent;
            }

            &:focus,
            &:active {
                opacity: 0.5;
            }
        }
    }

    .gallery-image--medium {
        img {
            width: 50%;
            max-width: 512px;
            height: 50%;
        }
    }

    .gallery-image--small {
        img {
            max-width: 30%;
            height: 20%;
        }
    }

    .headshot-container {
        padding-bottom: 64px;

        img {
            display: block;
            margin: auto;
            height: 196px;
            width: 196px;
        }
    }

    .container--padded {
        padding: 64px 0;
    }

    .text-box {
        margin: auto;
        padding: 0 16px;
        width: 768px;
        max-width: 960px;
        word-wrap: break-word;
        display: block;
    }

    .gallery-text {
        margin: auto;
        padding: 64px 0;
        width: 768px;
        max-width: 900px;
        word-wrap: break-word;
        display: block;
    }

    .image_container {
        .col-lg-12 {
            padding: 0;
        }

        .col-xs-12 {
            padding: 0;
        }
    }

    .gallery {
        height: 100%;
        display: inline-block;
        position: relative;
        width: 100%;
    }

    .gallerytile {
        height: 512px;
        background-color: #39393D;
        padding: 0;
        border-top: solid 2px $vb-primary;

        span.gallery-image {
            background-repeat: no-repeat;
        }
    }

    span.header-image {
        padding: 16px;
        height: $tile-height-medium;
        background-repeat: no-repeat;
    }

    #steammobile {
        @include background-image("../img/work/thumbs/gallery_thumb_steam.jpg");
    }

    #granditude {
        @include background-image("../img/work/thumbs/gallery_thumb_granditude.jpg");
    }

    #prettyugly {
        @include background-image("../img/work/thumbs/gallery_thumb_prettyugly.jpg");
    }

    #barbus {
        @include background-image("../img/work/thumbs/gallery_thumb_copafeel.jpg");
    }

    #adhop {
        @include background-image("../img/work/thumbs/gallery_thumb_adhop.jpg");
    }

    #dukebox {
        @include background-image("../img/work/thumbs/gallery_thumb_dukebox.jpg");
    }

    #visualbear {
        @include background-image("../img/work/thumbs/gallery_thumb_visualbear.jpg");
    }

    #lynx {
        @include background-image("../img/work/thumbs/gallery_thumb_lynx.jpg");
    }

    #sos {
        @include background-image("../img/work/thumbs/gallery_thumb_sos.jpg");
    }
}

//960< & >767px
@media (max-width: $break-big) and (min-width: $break-medium) {
    .paragraph-container--icons {
        img {
            width: 25%;
            height: 25%;
        }
    }

    .gallery-image {
        height: auto;

        img {
            width: 100%;
            height: auto;
            display: block;
            margin: auto;
        }

        a > img {
            &:hover {
                opacity: 0.75;
                background-color: transparent;
            }

            &:focus,
            &:active {
                opacity: 0.5;
            }
        }
    }

    .gallery-image--medium {
        img {
            width: 50%;
            height: 50%;
        }
    }

    .gallery-image--small {
        img {
            max-width: 30%;
            height: 20%;
        }
    }

    .headshot-container {
        padding-bottom: 64px 0;

        img {
            display: block;
            margin: auto;
            height: 196px;
            width: 196px;
        }
    }

    .text-box {
        margin: auto;
        padding: 0 16px;
        width: 768px;
        max-width: 900px;
        word-wrap: break-word;
        display: block;
    }

    .gallery-text {
        word-wrap: break-word;
        display: block;
        padding: 64px 16px;
    }

    .container {
        padding-bottom: 16px;
    }

    .container--padded {
        padding: 64px 0;
    }

    .image_container {
        .col-lg-12 {
            padding: 0;
        }

        .col-xs-12 {
            padding: 0;
        }
    }

    .gallery {
        height: 100%;
        display: inline-block;
        position: relative;
        width: 100%;

        .container {
            padding-bottom: 16px;
            padding-top: 48px;

            .image_container {
                padding-top: 32px;
                margin: auto;
            }
        }
    }

    .gallerytile {
        padding: 0;
        background-color: #39393D;
        border-top: solid 2px $vb-primary;

        span.gallery-image {
            height: $tile-height-big;
            background-repeat: no-repeat;
        }
    }

    span.header-image {
        padding: 16px;
        height: $tile-height-medium;
        background-repeat: no-repeat;
    }

    #steammobile {
        @include background-image("../img/work/thumbs/gallery_thumb_steam.jpg");
    }

    #granditude {
        @include background-image("../img/work/thumbs/gallery_thumb_granditude.jpg");
    }

    #prettyugly {
        @include background-image("../img/work/thumbs/gallery_thumb_prettyugly.jpg");
    }

    #barbus {
        @include background-image("../img/work/thumbs/gallery_thumb_copafeel.jpg");
    }

    #adhop {
        @include background-image("../img/work/thumbs/gallery_thumb_adhop.jpg");
    }

    #dukebox {
        @include background-image("../img/work/thumbs/gallery_thumb_dukebox.jpg");
    }

    #visualbear {
        @include background-image("../img/work/thumbs/gallery_thumb_visualbear.jpg");
    }

    #lynx {
        @include background-image("../img/work/thumbs/gallery_thumb_lynx.jpg");
    }

    #sos {
        @include background-image("../img/work/thumbs/gallery_thumb_sos.jpg");
    }
}

//767px< & >480px
@media (max-width: $break-medium) and (min-width: $break-small) {
    .paragraph-container--icons {
        img {
            width: 25%;
            height: 25%;
        }
    }

    .container {
        padding: 64px 0 16px 0;
    }

    .container--padded {
        padding: 64px 0;
    }

    .gallery-image {
        height: auto;

        img {
            width: 100%;
            height: auto;
            display: block;
            margin: auto;
        }

        a > img {
            &:hover {
                opacity: 0.75;
                background-color: transparent;
            }

            &:focus,
            &:active {
                opacity: 0.5;
            }
        }
    }

    .gallery-image--medium {
        img {
            width: 100%;
            height: 100%;
        }
    }

    .gallery-image--small {
        img {
            max-width: 50%;
            height: 50%;
        }
    }

    .headshot-container {
        padding-bottom: 32px;

        img {
            display: block;
            margin: auto;
            height: 128px;
            width: 128px;
        }
    }

    .text-box {
        margin: auto;
        padding: 0 32px;
        width: 100%;
        word-wrap: break-word;
        display: block;
    }

    .gallery-text {
        word-wrap: break-word;
        display: block;
        padding: 32px;
    }

    .image_container {
        .col-lg-12 {
            padding: 0;
        }

        .col-xs-12 {
            padding: 0;
        }
    }

    .gallery {
        height: 100%;
        display: inline-block;
        position: relative;
        width: 100%;

        .container {
            padding-bottom: 16px;

            .gallery-image {
                img {
                    display: block;
                    margin: auto;
                }
            }
        }
    }

    .gallerytile {
        padding: 0;
        background-color: #39393D;
        border-top: solid 2px $vb-primary;

        span.gallery-image {
            height: $tile-height-medium;
            background-repeat: no-repeat;
        }
    }

    span.header-image {
        padding: 16px;
        height: $tile-height-medium;
        background-repeat: no-repeat;
    }

    #steammobile {
        @include background-image("../img/work/thumbs/gallery_thumb_steam.jpg");
    }

    #granditude {
        @include background-image("../img/work/thumbs/gallery_thumb_granditude.jpg");
    }

    #prettyugly {
        @include background-image("../img/work/thumbs/gallery_thumb_prettyugly_mobile.jpg");
    }

    #barbus {
        @include background-image("../img/work/thumbs/gallery_thumb_copafeel_mobile.jpg");
    }

    #adhop {
        @include background-image("../img/work/thumbs/gallery_thumb_adhop_mobile.jpg");
    }

    #dukebox {
        @include background-image("../img/work/thumbs/gallery_thumb_dukebox_mobile.jpg");
    }

    #visualbear {
        @include background-image("../img/work/thumbs/gallery_thumb_visualbear_mobile.jpg");
    }

    #lynx {
        @include background-image("../img/work/thumbs/gallery_thumb_lynx_mobile.jpg");
    }

    #sos {
        @include background-image("../img/work/thumbs/gallery_thumb_sos_mobile.jpg");
    }
}

//480px< & >320px
@media (max-width: $break-small) and (min-width: $break-tiny) {
    .paragraph-container--icons {
        img {
            width: 25%;
            height: 25%;
        }
    }

    .gallery-image {
        height: auto;

        img {
            width: 100%;
            height: 100%;
            display: block;
            margin: auto;
        }

        a > img {
            &:hover {
                opacity: 0.75;
                background-color: transparent;
            }

            &:focus,
            &:active {
                opacity: 0.5;
            }
        }
    }

    .gallery-image--medium {
        img {
            width: 100%;
            height: 100%;
        }
    }

    .gallery-image--small {
        img {
            max-width: 75%;
            height: 75%;
        }
    }

    .headshot-container {
        padding-bottom: 32px;

        img {
            display: block;
            margin: auto;
            height: 128px;
            width: 128px;
        }
    }

    .container {
        padding: 64px 0 16px 0;
    }

    .container--padded {
        padding: 64px 0;
    }

    .text-box {
        word-wrap: break-word;
        display: block;
        width: 100%;
        padding: 0 32px;
        margin: auto;
    }

    .gallery-text {
        word-wrap: break-word;
        display: block;
        padding: 32px;
        margin: auto;
    }

    .image_container {
        .col-lg-12 {
            padding: 0;
        }

        .col-xs-12 {
            padding: 0;
        }
    }

    .gallery {
        height: 100%;
        display: inline-block;
        position: relative;
        width: 100%;
    }

    .gallerytile {
        padding: 0;
        background-color: #39393D;
        border-top: solid 2px $vb-primary;

        span.gallery-image {
            height: $tile-height-medium;
            background-repeat: no-repeat;
        }
    }

    span.header-image {
        padding: 16px;
        height: 192px;
        background-repeat: no-repeat;
    }


    #steammobile {
        @include background-image("../img/work/thumbs/gallery_thumb_steam.jpg");
    }

    #granditude {
        @include background-image("../img/work/thumbs/gallery_thumb_granditude.jpg");
    }

    #prettyugly {
        @include background-image("../img/work/thumbs/gallery_thumb_prettyugly_mobile.jpg");
    }

    #barbus {
        @include background-image("../img/work/thumbs/gallery_thumb_copafeel_mobile.jpg");
    }

    #adhop {
        @include background-image("../img/work/thumbs/gallery_thumb_adhop_mobile.jpg");
    }

    #dukebox {
        @include background-image("../img/work/thumbs/gallery_thumb_dukebox_mobile.jpg");
    }

    #visualbear {
        @include background-image("../img/work/thumbs/gallery_thumb_visualbear_mobile.jpg");
    }

    #lynx {
        @include background-image("../img/work/thumbs/gallery_thumb_lynx_mobile.jpg");
    }

    #sos {
        @include background-image("../img/work/thumbs/gallery_thumb_sos_mobile.jpg");
    }
}

//320px<
@media (max-width: $break-tiny) {
    .paragraph-container--icons {
        img {
            width: 25%;
            height: 25%;
        }
    }

    .gallery-image {
        height: auto;

        img {
            width: 100%;
            height: 100%;
            display: block;
            margin: auto;
        }

        a > img {
            &:hover {
                opacity: 0.75;
                background-color: transparent;
            }

            &:focus,
            &:active {
                opacity: 0.5;
            }
        }
    }

    .gallery-image--medium {
        img {
            width: 100%;
            height: 100%;
        }
    }

    .gallery-image--small {
        img {
            max-width: 100%;
            height: 100%;
        }
    }

    .headshot-container {
        padding-bottom: 32px;

        img {
            display: block;
            margin: auto;
            height: 128px;
            width: 128px;
        }
    }

    .container {
        padding: 64px 0 16px 0;
    }

    .container--padded {
        padding: 64px 0;
    }

    .text-box {
        word-wrap: break-word;
        display: block;
        width: 100%;
        padding: 0 32px;
        margin: auto;
    }

    .gallery-text {
        word-wrap: break-word;
        display: block;
        padding: 32px;
        margin: auto;
    }

    .image_container {
        .col-lg-12 {
            padding: 0;
        }

        .col-xs-12 {
            padding: 0;
        }
    }

    .gallery {
        height: 100%;
        display: inline-block;
        position: relative;
        width: 100%;

        .container {
            padding-bottom: 16px;

            .gallery-image {
                img {
                    display: block;
                    margin: auto;
                }
            }
        }
    }

    .gallerytile {
        padding: 0;
        background-color: #39393D;
        border-top: solid 2px $vb-primary;

        span.gallery-image {
            height: $tile-height-medium;
            background-repeat: no-repeat;
        }
    }

    span.header-image {
        padding: 16px;
        color: $vb-light;
        height: 128px;
        background-repeat: no-repeat;
    }


    #steammobile {
        @include background-image("../img/work/thumbs/gallery_thumb_steam.jpg");
    }

    #granditude {
        @include background-image("../img/work/thumbs/gallery_thumb_granditude.jpg");
    }

    #prettyugly {
        @include background-image("../img/work/thumbs/gallery_thumb_prettyugly_mobile.jpg");
    }

    #barbus {
        @include background-image("../img/work/thumbs/gallery_thumb_copafeel_mobile.jpg");
    }

    #adhop {
        @include background-image("../img/work/thumbs/gallery_thumb_adhop_mobile.jpg");
    }

    #dukebox {
        @include background-image("../img/work/thumbs/gallery_thumb_dukebox_mobile.jpg");
    }

    #visualbear {
        @include background-image("../img/work/thumbs/gallery_thumb_visualbear_mobile.jpg");
    }

    #lynx {
        @include background-image("../img/work/thumbs/gallery_thumb_lynx_mobile.jpg");
    }

    #sos {
        @include background-image("../img/work/thumbs/gallery_thumb_sos_mobile.jpg");
    }
}
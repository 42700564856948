body,
html {
    height: 100%;
    box-sizing: border-box;
}

section {
    width: 100%;
}

.main {
    height: auto;
}

.row {
    padding: 0;
    margin: 0;
}

.box-container {
    padding: 8px;
    width: 100%;
    height: 128px;
    display: block;
}

.section#jumbo {
    border: 0;
}

.div#work {
    margin-top: 100vh;
    background-color: color("grey", "base");
}

.section {
    padding: 0;
    margin: 0;
}

.m3, .m4, .m6, .col-xs-6, .m8, .m9 {
    margin: 0;
    padding: 0;
}

.m12, .s12 {
    margin: 0;
    padding: 0;
}

.text-columns {
    margin: 8px 0;

    h4 {
        text-align: center;
    }

    .m6 {
        padding: 8px;
    }
}

.container, .container__open {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    padding-top: 48px;
}

//>2500px
@media (min-width: $break-huge) {
    .outer-container {
        display: table;
        width: 100%;
        height: 100vh;
        min-height: 256px;
        transition: height 0.1s ease;

        .inner-container {
            display: table-cell;
            vertical-align: middle;

            .text-container {
                padding: 64px 32px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .section {
        width: 100%;
        min-height: 100vh;
        max-height: 100%;
        position: relative;
        display: inline-block;

        .paragraph-container {
            padding: 32px 0;
        }

        .gallery-image--medium {
            img {
                width: 50%;
                height: 50%;
            }
        }
    }
}

//2500px< & >1200px
@media (max-width: $break-huge) and (min-width: $break-large) {
    .outer-container {
        display: table;
        width: 100%;
        height: 100vh;
        min-height: 256px;
        transition: height 0.1s ease;

        .inner-container {
            display: table-cell;
            vertical-align: middle;

            .text-container {
                padding: 64px 32px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .section {
        width: 100%;
        position: relative;
        display: inline-block;
        min-height: 100vh;
        max-height: 100%;

        .gallery-image--medium {
            img {
                width: 50%;
                height: 50%;
            }
        }
    }
}

//1280px< & >767px
@media (max-width: $break-large) and (min-width: $break-medium) {
    .outer-container {
        display: table;
        width: 100%;
        height: 100vh;
        min-height: 256px;
        transition: height 0.1s ease;

        .inner-container {
            display: table-cell;
            vertical-align: middle;

            .text-container {
                padding: 64px 32px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .section {
        width: 100%;
        min-height: 100vh;
        max-height: 100%;

        .gallery-image--medium {
            img {
                width: 50%;
                height: 50%;
            }
        }
    }
}

//767px< & >480px
@media (max-width: $break-medium) and (min-width: $break-small) {
    .outer-container {
        display: table;
        width: 100%;
        height: 100vh;
        min-height: 256px;
        transition: height 0.1s ease;

        .inner-container {
            display: table-cell;
            vertical-align: middle;

            .text-container {
                padding: 32px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .section {
        width: 100%;
        min-height: 100vh;
        max-height: 100%;

        .text-container {
            padding: 128px 32px;
        }
    }
}

//480px< & >320px
@media (max-width: $break-small) and (min-width: $break-tiny) {
    .outer-container {
        display: table;
        width: 100%;
        height: 100vh;
        min-height: 256px;
        transition: height 0.1s ease;

        .inner-container {
            display: table-cell;
            vertical-align: middle;

            .text-container {
                padding: 128px 0;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .section {
        min-height: 100vh;
        max-height: 100%;
        width: 100%;
        padding: 0;
        margin-right: -32px;

        .text-container {
            padding: 128px 0;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

//320px<
@media (max-width: $break-tiny) {
    .outer-container {
        display: table;
        width: 100%;
        height: 100vh;
        min-height: 256px;
        transition: height 0.1s ease;

        .inner-container {
            display: table-cell;
            vertical-align: middle;

            .text-container {
                padding: 128px 0;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .section {
        width: 100%;
        min-height: 100vh;
        max-height: 100%;

        .gallery-image--medium {
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
// hero styles

.hero {
    display: flex;
    align-items: center;
    color: $vb-light;
    width: 100%;
    height: 100vh;
    font-weight: bold;
    position: fixed;

    .content {
        padding-top: 32px;
    }

    a {
        text-decoration: underline;
        color: $vb-light;
        font-weight: inherit;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $vb-light;
            background-color: $vb-primary;
        }
    }
}

@include mq($until: mobile) {
    .hero {
        height: 100vh;
    }
}


// Apply styling up to devices smaller than tablets (exclude tablets)
@include mq($from: mobile, $until: tablet) {
    .hero {
        height: 100vh;
    }
}


// Same thing, in landscape orientation
@include mq($until: tablet, $and: "(orientation: landscape)") {
    .hero {
        height: 100vh;
    }
}


// Apply styling to tablets up to desktop (exclude desktop)
@include mq(desktop) {
    nav {}
}
.section {
    background-color: $vb-light;
    color: $vb-grey;

    .emphasis {
        color: $vb-grey;
    }
}

.section#jumbo {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff621a+0,313135+91 */
    background: #ff621a;

    /* Old browsers */
    background: -moz-linear-gradient(top, #e64900 0%, #252528 100%);

    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #e64900 0%, #252528 100%);

    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #e64900 0%, #252528 100%);

    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff621a', endColorstr='#252528',GradientType=0 );

    /* IE6-9 */

    color: $vb-light;

    a {
        text-decoration: none;
        color: $vb-primary;
        font-weight: $font-semi-bold;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $vb-light;
            background-color: $vb-primary;
        }
    }
}

#climateclock_container {
    color: $vb-dark;
    background-color: #fff6f7;

    .gallery-image {
        // background-color: #00a2de;
   
    }

    .dark-bg {
        background-color: #000;
        color: $vb-light;

        a {
            color: $vb-light;
        }

        .image_text {
            color: $vb-light;
        }
    }

    .red-bg {
        background-color: #FF2020;
        color: #F6F6F6;

        a {
            color: $vb-light;
        }

        .image_text {
            color: #F6F6F6;
        }
    }

    .light-bg {
        color: $vb-dark;
        background-color: #fff;
    }
}

#artent_container {
    color: $vb-dark;
    background-color: #F0F4FC;

    .gallery-image {
        // background-color: #00a2de;
   
    }

    .dark-bg {
        background-color: #975FBF;
        color: $vb-light;

        a {
            color: $vb-light;
        }

        .image_text {
            color: $vb-light;
        }
    }

    .light-bg {
        color: $vb-dark;
        background-color: #fff;
    }
}

#steammobile_container {
    color: $vb-light;
    background-color: #00a2de;

    .gallery-image {
        // background-color: #00a2de;
   
    }

    .dark-bg {
        background-color: #1a283a;
    }

    .light-bg {
        color: $vb-dark;
        background-color: #fff;
    }
}

#granditude_container {
    color: $vb-grey;
    background-color: #e6fcff;

    .gallery-image {
        background-color: #e6fcff;
    }
}

#barbus_container {
    color: $vb-grey;
    background-color: #e2eaec;

    .gallery-image {
        background-color: #e2eaec;
    }
}

#visualbear_container {
    color: $vb-grey;
    background-color: #ebf5ff;

    .gallery-image {
        // background-color: #ebf5ff;
    }
}

#adhops_container {
    color: $vb-grey;
    background-color: #e9e5e4;

    .gallery-image {
        background-color: #e9e5e4;
    }
}

#dukebox_container {
    color: $vb-grey;
    background-color: #e1d2cd;

    .gallery-image {
        background-color: #e1d2cd;
    }
}

#lynx_container {
    color: $vb-grey;
    background-color: #f8edd1;

    .gallery-image {
        background-color: #f8edd1;
        color: $vb-light;
    }
}

#sos_container {
    color: $vb-grey;
    background-color: #fef6e9;

    .gallery-image {
        background-color: #fef6e9;
        color: $vb-light;
    }
}